
<template>
    <div class="logo-overlay">
    </div>
</template>

<style lang="css" scoped>
@import "../../assets/styles/main.css";

.logo-overlay {
  position: absolute;
  top: 0;
  right: 57px;
  height: 101.5px;
  width: 97.6px;
  background-repeat: no-repeat;
  background-image: url('assets/images/logo.svg');
}
</style>
